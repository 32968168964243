import React, { Component } from 'react'
import { graphql } from 'gatsby'

import Layout from '../../../components/theme/Layout'
import Footer from '../../../components/theme/Footer'
import Header from '../../../components/theme/Header'
import ImageHeader from '../../../components/theme/ImageHeader'
import RenderBlock from '../../../components/blocks/RenderBlock'
import sendEvent from '../../../helpers/sendAnalytics';
import Offers from "../../../components/common/Offers";

class Category extends Component {
  componentWillMount() {
    const { pageContext } = this.props
    const { guid } = pageContext

    const events = {
      fb: 'ViewContent',
      snapchat: 'VIEW_CONTENT'
    };

    switch (guid) {
      // BATHROOM TISSUE
      case '3f28be5e-63ac-434b-9371-8511b5defcaf':
        events.gtag = {
          send_to: 'AW-951001886/vWAMCLvygZMBEJ7GvMUD'
        };
        break;
      // PAPER TOWEL
      case 'fc61d565-257d-40b3-b1c4-34cac3f03193':
        events.gtag = {
          send_to: 'AW-951001886/pCEuCNbX-pIBEJ7GvMUD'
        };
        break;
      // FACIAL TISSUE
      case '50063444-203d-4baf-8e1e-629613766fa1':
        break;
      default:
    }

    // EVENT: BuyOnline
    sendEvent(events)
  }

  render() {
    const { data } = this.props

    return (
      <Layout
        meta={data.productCategoryPage.meta}
        urls={data.productCategoryPage.urls}
        locale='zh-hant'
      >
        <Header
          urls={data.productCategoryPage.urls}
          locale='zh-hant'
        />

        <ImageHeader
          title={data.productCategoryPage.description}
          image={data.productCategoryPage.image_url}
          alt={data.productCategoryPage.title_html}
        />

        <RenderBlock blocks={data.productCategoryPage.blocks} />

        <Footer image={data.productCategoryPage.footer_image} />
        <Offers />

      </Layout>
    )
  }
}

export const query = graphql`
query ($guid: String!) {
  productCategoryPage(guid: { eq: $guid }, locale: {eq:"zh-hant"}) {
    guid
    title
    description
    footer_image
    image_url
    blocks {
      __typename
      ...productCategoriesBlockFragment
      ...imageLeftTextRightBlockFragment
      ...imageRightTextLeftBlockFragment
      ...productLandingBlockFragment

      ... on ProductCategoryBlock {
        guid
        id
        modification
        description
        video
        product_header
        logo_image_url
        product_category_image_url
        products {
          id
          title
          title_html
          guid
          images_url
        }
      }
    }
    other_category_1 {
      preview_image_url
      title
      guid
    }
    other_category_2 {
      preview_image_url
      title
      guid
    }
    other_category_3 {
      preview_image_url
      title
      guid
    }
    urls {
      en
      fr
      zh_hant
      zh_hans
    }
    meta {
      description
      keywords
      title
    }
  }
}
`

export default Category
